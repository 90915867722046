import React from 'react';
import styles from '../styles/CheckIn.module.css';

// Define the shape of the props
// TODO - should this be moved to interfaces file?
interface CheckInProps {
  date: string;
  bodyweight: number;
  steps: number;
  phase: string;
  fat: number;
  protein: number;
  carbohydrates: number;
  calories: number;
  comment: string;
  onDelete: () => void;
  onEdit: () => void;
}

// Helper function to get week number and year from a date
const getWeekNumberAndYear = (dateString: string) => {
  const date = new Date(dateString);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  const jan4 = new Date(date.getFullYear(), 0, 4);
  const daysBetween = (date.getTime() - jan4.getTime()) / (24 * 60 * 60 * 1000);
  const weekNumber = Math.ceil((daysBetween + 1) / 7);
  const year = date.getFullYear();
  return `Week ${weekNumber}, ${year}`;
};

const getCleanDate = (dateString: string) => {
  // const cleanDate = new Date(dateString);
  const cleanDate = formatDateToYYYYMMDD(dateString);
  return `${cleanDate}`;
}

// Function to format a date to YYYY-MM-DD
function formatDateToYYYYMMDD(date: Date | string) {
  // console.log('Input date:', date); // Add this for debugging
  const d = new Date(date);
  // console.log('Parsed date:', d); // And this to see the parsed result
  let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}


const CheckIn: React.FC<CheckInProps> = ({ date, bodyweight, steps, phase, fat, protein, carbohydrates, calories, comment, 
  onDelete, onEdit }) => {
    // const weekNumberAndYear = getWeekNumberAndYear(date);
    const cleanedDate = getCleanDate(date);
  return (
    <div>
      <div className={styles.checkIn}>
        {/* <div>{date}</div> */}
        <div>{cleanedDate}</div>
        {/* <div>{weekNumberAndYear}</div> */}
        <div>{bodyweight ? `${bodyweight} kg` : '-'}</div>
        <div>{steps ? `${steps} steps` : '-'}</div>
        <div>{phase ? `${phase}` : '-'}</div>
        <div>{fat ? `${fat} f` : '-'}</div>
        <div>{protein ? `${protein} p` : '-'}</div>
        <div>{carbohydrates ? `${carbohydrates} c` : '-'}</div>
        <div>{calories ? `${calories} cals` : '-'}</div>
        <div>{comment}</div>
        <button className={styles.button} onClick={onDelete}>Delete</button>
        <button className={styles.button} onClick={onEdit}>Edit</button>
      </div>
    </div>
  );
}

export default CheckIn;
