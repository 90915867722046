import React from 'react';
import styles from '../styles/LoginButton.module.css';


function LoginButton() {
  const handleLogin = () => {
    // Check if we're in development mode
    if (process.env.NODE_ENV === 'development') {
      // Redirect to mock login for development
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/mocklogin`;
    } else {
      // Redirect to Google login for production
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/google`;
    }
  };

  return (
    <button className={styles.button} onClick={handleLogin}>Login</button>
  );
}

export default LoginButton;
