import React, { useState, useEffect, useRef } from 'react';
import CheckInForm from './CheckInForm';
import CheckInList from './CheckInList';
import CheckinButton from './CheckinButton';  
import styles from '../styles/Main.module.css';
import axios from 'axios';
import WeightLineChart from './WeightLineChart';
import ProgressDisplayTable from './ProgressDisplayTable';
import ConfirmationDialog from './ConfirmationDialog'; 
import WeeklyStreakCard from './WeeklyStreakCard';
import { useLocation } from 'react-router-dom';

axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
axios.defaults.withCredentials = true;

// TODO - modularize the code
function Main({ isAuthenticated }) {
    // state variables
    const [dailyCheckIns, setDailyCheckIns] = useState([]);
    const [weeklyCheckIns, setWeeklyCheckIns] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [checkInToDeleteIndex, setCheckInToDeleteIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [streak, setStreak] = useState(0);

    // regular variables
    const location = useLocation();

    useEffect(() => {
        // Remove the leading slash from the pathname if it exists
        const pathWithoutLeadingSlash = location.pathname.startsWith('/')
            ? location.pathname.slice(1)
            : location.pathname;
        // Now set the document title using the modified path
        document.title = `Fitness CheckIn - ${pathWithoutLeadingSlash.replace('/', ' - ')}`;
    }, [location]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchData();
        }
    }, [isAuthenticated]);

    const fetchData = async () => {
        await fetchCheckIns();
        await fetchWeeklyCheckIns();
        await fetchStreak();
    };

    const fetchStreak = async () => {
        if (!isAuthenticated) return;
        try {
            const response = await axios.get('/user/streak'); 
            setStreak(response.data.streak);  
        } catch (error) {
            console.error("Error fetching streak:", error);
        }
    };
  

    useEffect(() => {
        const checkViewportSize = () => {
            setIsMobile(window.matchMedia('(max-width: 700px)').matches);
    };
        // Check once and register for updates
        checkViewportSize();
        window.addEventListener('resize', checkViewportSize);

        // Cleanup on component unmount
        return () => window.removeEventListener('resize', checkViewportSize);
    }, []);

  const fetchCheckIns = async () => {
    try {
      const response = await axios.get('/checkin');
      if (Array.isArray(response.data)) {
        const sortedCheckIns = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setDailyCheckIns(sortedCheckIns);
      } else {
        console.warn('Received non-array checkIns:', response.data);
        setDailyCheckIns([]);
      }
    } catch (error) {
      console.error("Error fetching check-ins:", error);
    }
  };

  const fetchWeeklyCheckIns = async () => {
    if (!isAuthenticated) return;

    try {
        const response = await axios.get('/checkin/weekly');
        // Explicitly handle both empty and filled data arrays
        setWeeklyCheckIns(response.data.length > 0 ? response.data : []);
    } catch (error) {
        console.error("Error fetching weekly check-ins:", error);
        setWeeklyCheckIns([]);  // Ensure state is cleared on error as well
    }
};

  
  
  const handleNewCheckIn = (newCheckIn) => {
    // Previously defined code to handle new check-ins
    return axios.post('/checkin', newCheckIn)
      .then(response => {
        // Code to update daily check-ins
        fetchData()
      })
      .catch(error => {
        console.error("Error adding new check-in:", error);
        alert("Failed to add the new check-in. Please try again.");
      });
};



  
  const handleUpdateCheckIn = (index, updatedCheckIn) => {
    // Previously defined code to handle updates
    return axios.put(`/checkin/${updatedCheckIn.id}`, updatedCheckIn)
        .then(response => {
            fetchData();
        })
        .catch(error => {
            console.error("Error updating check-in:", error);
            alert("Failed to update the check-in. Please try again.");
        });
  };

    const handleDeleteCheckIn = index => {
        // Open the confirmation dialog and store the index of the check-in to delete
        setCheckInToDeleteIndex(index);
        setConfirmDialogOpen(true);
    };

    const confirmDelete = () => {
      const checkInToDelete = dailyCheckIns[checkInToDeleteIndex];
      // Optimistically update UI for check-ins using frontend filtering to hide it
      const newCheckIns = dailyCheckIns.filter((item, index) => index !== checkInToDeleteIndex);
      setDailyCheckIns(newCheckIns);
      setWeeklyCheckIns([]);  // Assume the weekly data needs refresh too
    
      axios.delete(`/checkin/${checkInToDelete.id}`)
        .then(response => {
            // console.log('Check-in deleted:', checkInToDelete);
            // Fetch all data including streak after the delete confirmation
            fetchData();  // This will re-fetch check-ins, weekly data, and streak
          })
          .catch(error => {
            console.error("Error deleting check-in:", error);
            alert("Something went wrong when deleting. Please try again.");
            // Roll back optimistic updates on error
            fetchData();  // This fetch is to ensure consistency in case of an error
          })
          .finally(() => {
            setConfirmDialogOpen(false);  // Always close the dialog
            fetchStreak();  // Fetch the streak separately to ensure it's updated
        });
    };


  const cancelDelete = () => {
    // Close the confirmation dialog
    setConfirmDialogOpen(false);
  };
    

  // Function to set which check-in is being edited
  const handleEditCheckIn = index => {
    setEditingIndex(index);
    setModalOpen(true);  // Open the modal
  };
    

    const [isModalOpen, setModalOpen] = useState(false); // New state variable for modal

    const closeModal = () => {
      // closes both the new, edit, and delete checkin modals
        setModalOpen(false);
        setEditingIndex(null);  // Reset the editing index on close
        cancelDelete();
    };

    // Function to close modal if clicking on backdrop
    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    };

    const weeklyCheckInDates = weeklyCheckIns.map(checkIn => checkIn.week_start);

  return (
    <div className={styles.main}>
        <section className={styles.main__top_section}>
            <CheckinButton setEditingIndex={setEditingIndex} setModalOpen={setModalOpen} />
            <WeeklyStreakCard streak={streak} /> 
        </section> 

    {isModalOpen && (
        <div className={styles.modal} onClick={handleBackdropClick}>
        <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={() => { setEditingIndex(null); setModalOpen(false); }}>X</button>
            <CheckInForm 
                onSubmit={(data) => { 
                    const operation = editingIndex !== null 
                        ? handleUpdateCheckIn(editingIndex, data)
                        : handleNewCheckIn(data);
                    operation.then(() => {
                    closeModal(); // Ensure closeModal is called only after the operation completes
                }).catch(error => {
                    console.error('Error during check-in operation:', error);
                });
            }} 
                initialData={editingIndex !== null ? dailyCheckIns[editingIndex] : null}
                checkIns={dailyCheckIns}
                editingIndex={editingIndex}
            />
        </div>
    </div>
    )}

      <ProgressDisplayTable 
        checkIns={weeklyCheckIns} 
      />

      <WeightLineChart 
        checkIns={weeklyCheckIns}>
      </WeightLineChart>
      {!isMobile && 
        <CheckInList 
          checkIns={dailyCheckIns} 
          onDelete={handleDeleteCheckIn} 
          onEdit={handleEditCheckIn} 
        />
      }

      {isConfirmDialogOpen && (
        <div className={styles.modal} onClick={handleBackdropClick}>
          <ConfirmationDialog
            message="Are you sure you want to delete this check-in? It can't be undone."
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
          />
        </div>
      )}
    </div>
  );
}

export default Main;
